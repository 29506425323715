import { createTheme } from '@material-ui/core'

const typography = {
  useNextVariants: true,
  fontFamily: "'Montserrat','Helvetica Neue',Helvetica,Arial,sans-serif",
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  h1: {
    fontSize: '50px',
    fontWeight: 600
  },
  h2: {
    fontSize: '24px',
    fontWeight: 600
  },
  h3: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: 'normal'
  },
  h4: {
    fontSize: '14px',
    fontWeight: 400
  },
  h5: {
    fontSize: '12px',
    fontWeight: 400
  },
  body1: {
    fontSize: '14px',
    fontWeight: 400
  },
  body2: {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '1px'
  }
}

// All the following keys are optional.
// We try our best to provide a great default value.
const theme = createTheme({
  palette: {
    secondary: {
      light: '#fcc',
      main: '#AF1818',
      dark: '#800'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    primary: {
      light: '#cec',
      main: '#4ba31d',
      dark: '#0E6969'
    },
    text: {
      primary: '#0E6969',
      secondary: '#800'
    }

    // error: will use the default color
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    values: { xs: 0, sm: 400, md: 600, lg: 960, xl: 1280, xxl: 1920 }
  },
  typography,
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        paddingTop: '8px',
        verticalAlign: 'top'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        '@media only screen and (max-width: 960px)': {
          padding: '5px'
        }
      }
    }
  }
})

export default theme
