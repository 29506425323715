import React, { useMemo } from 'react'

import { format } from 'date-fns'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
} from '@material-ui/core'

import getGinId from '../../utils/getGinId'
import config from '../../config'
import {useBuilderConfig} from "../../hooks/useBuilderConfig"

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: 140,
    color: 'black',
  },
  capsuleImage: {
    backgroundColor: '#eee',
    margin: 10,
    width: 80,
    minWidth: 80,
    height: 80,
  },
  customer: {
    maxWidth: '100%',
  },
  content: {
    width: '100%',
    marginTop: '-10px',
    marginLeft: theme.spacing(2),
  },
  productionCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 60,
    minWidth: '100%',
    // backgroundColor: '#cdecc0',
    marginRight: 10,
    marginBottom: 0,
  },
  productionInnerCard: {
    display: 'flex',
    marginTop: '0px',
    alignItems: 'center',
  },
  gridRoot: {
    width: '100%',
    flexGrow: 1,
  },
  multipleCart: {
    fontSize: '13px',
    backgroundColor: '#cc0000',
    marginTop: '7px',
    marginBottom: '7px',
    color: '#ffffff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '8px',
    padding: '4px',
    textAlign: 'center',
  },
  upsell: {
    fontSize: '14px',
    backgroundColor: '#4d8e00',
    color: '#ffffff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '8px',
    padding: '4px',
    textAlign: 'center',
  },
  detailsBox: {
    padding: '0px 5px',
    marginLeft: '-5px',
  },
  package: {
    fontSize: '14px',
    marginTop: '7px',
    marginBottom: '7px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '8px',
    padding: '4px',
    textAlign: 'center',
    border: '1px solid grey',
  },
  capsule: {
    display: "flex",
    flexDirection: "column",
    fontSize: '14px',
    color: 'black',
    marginTop: '7px',
    marginBottom: '7px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '8px',
    padding: '4px',
    textAlign: 'center',
    alignItems: 'center',
  },
  bottle: {
    fontSize: '14px',
    border: '1px solid grey',
    color: 'black',
    marginTop: '7px',
    marginBottom: '7px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '8px',
    padding: '4px',
    textAlign: 'center',
  },
  bottleNoBorder: {
    fontSize: '14px',
    color: 'black',
    marginTop: '7px',
    marginBottom: '7px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '8px',
    padding: '4px',
    textAlign: 'center',
  },
  hashChanged: {
    fontSize: '13px',
    backgroundColor: '#fff',
    color: '#dca153',
    border: '1px solid #dca153',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '8px',
    padding: '4px',
    textAlign: 'center',
  },
}))

const ProductionInfo = ({ production }) => {
  const classes = useStyles()

  if (!production?.day) {
    return
  }

  return (
    <div className={classes.productionCard}>
      <div className={classes.productionInnerCard}>
        <Typography style={{ marginRight: 10 }}>
          {format(new Date(production.day), 'yyyy-MM-dd')}
        </Typography>
      </div>
      <div className={classes.productionInnerCard}>
        <Typography variant="h3" color="textSecondary">
          {production.numbers.length <= 6
            ? production.numbers.join('-')
            : `${production.numbers[0]}...${
                production.numbers[production.numbers.length - 1]
              }`}
        </Typography>
      </div>
    </div>
  )
}

const OrderWidget = ({ order, showCapsule }) => {
  const classes = useStyles()
  const history = useHistory()
  const {getCustomizationLabel} = useBuilderConfig()
  const params = useParams()
  const area = params.area

  const productId = useMemo(() => {
    return getGinId(order)
  }, [order])

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Grid container className={classes.gridRoot} spacing={2}>
          <Grid item xs={4}>
            <div>
              <Button
                className={classes.detailsBox}
                size="small"
                onClick={() =>
                  history.push(`/${area}/orders-p1/${order.ginId}`)
                }
              >
                {productId}
              </Button>
              {order.paymentDate && (
                <Typography variant="body2" gutterBottom>
                  Del {format(new Date(order.paymentDate), 'dd MMM, HH:mm')}
                </Typography>
              )}
              <Typography variant="body2" className={classes.customer}>
                {order.shippingAddress.first_name}{' '}
                {order.shippingAddress.last_name}{' '}
                {order.shippingAddress.company}
                <br />
                {order.shippingAddress.city}
                <br />
                {order.shippingAddress.state} ({order.shippingAddress.country})
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <div>
                <ProductionInfo production={order.production} bottles={order.quantity}/>
              </div>

              {!showCapsule && order.relatedProducts?.length > 0 && (
                <div className={classes.multipleCart}>
                  <span>Carrello multiplo</span>
                </div>
              )}

              {showCapsule && (
                <div className={classes.bottleNoBorder}>
                  {order.quantity} {getCustomizationLabel('bottle', order.customizations?.bottle?.id)}
                </div>
              )}

              {!showCapsule && order.hashChanged ? (
                <div className={classes.hashChanged}>Ordine modificato</div>
              ) : (
                ''
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              {!showCapsule && (
                <div className={classes.bottle}>
                  {order.quantity} {getCustomizationLabel('bottle', order.customizations?.bottle?.id)}
                </div>
              )}
              {showCapsule ? (
                <>
                  <div className={classes.capsule}>
                    {getCustomizationLabel('capsule', order.customizations?.capsule?.id)}
                    <CardMedia
                      className={classes.capsuleImage}
                      image={`${config.assetsPublicBaseUrl}images/builder2/picker/capsule_${order.customizations.capsule.id}.png`}
                      title=""
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={classes.package}
                    style={{
                      backgroundColor:
                        order.params.packaging === 'box' ? 'blue' : '',
                      color: order.params.packaging === 'box' ? 'white' : '',
                      border:
                        order.params.packaging === 'box'
                          ? '1px solid blue'
                          : '1px solid grey',
                    }}
                  >
                    {order.params.packaging}
                  </div>
                  {order.upsellProducts?.length || order.hasFakeUpselling ? (
                    <div className={classes.upsell}>UPSELL</div>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default OrderWidget
