import React from 'react'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

const CustomerData = (props) => {
  const classes = useStyles()
  const { data, isBilling, isShipping } = props

  if (!isBilling && !isShipping) {
    return false
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3" color="secondary" gutterBottom>
        {isBilling ? 'Fatturazione' : 'Spedizione'}
      </Typography>
      {data.company && (
        <Typography variant="h2" gutterBottom>
          {data.company}
        </Typography>
      )}
      {(data.first_name || data.last_name) && (
        <Typography variant="h2" gutterBottom>
          {`${data.first_name } ${ data.last_name}`}
        </Typography>
      )}
      <Typography variant="h3">
        {data.address_1}, {data.address_2}
      </Typography>
      <Typography variant="h3" gutterBottom>
        {data.postcode} {data.city} ({data.state})
      </Typography>
      {isBilling && (
        <Typography variant="h4">
          <strong>Email:</strong> {data.email}
        </Typography>
      )}
      {isBilling && (
        <Typography variant="h4">
          <strong>Phone:</strong> {data.phone}
        </Typography>
      )}
    </div>
  )
}

export default CustomerData
