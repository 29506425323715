import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyle = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1280px',
      width: '1280px',
      margin: '0 auto'
    }
  }
}))

export const Container = ({ children }) => {
  const classes = useStyle()
  return <div className={classes.root}>{children}</div>
}

export default Container
