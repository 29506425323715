const config = {
  assetsBaseUrl: '/assets/',
  flags: {
    mdg: process.env.REACT_APP_FF_MDG,
  },
  baseUrl: '/',
  serverUrl: process.env.REACT_APP_API_URL,
  configApiUrl: process.env.REACT_APP_CONFIG_API_URL,
  labelsApiUrl: process.env.REACT_APP_LABELS_API_URL,
  assetsPublicBaseUrl: `${process.env.REACT_APP_STATICS_URL}assets/`,
  staticsPublicBaseUrl: process.env.REACT_APP_STATICS_URL,
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    // identityPoolId: process.env.REACT_APP_AMPLIFY_IDENTITY_POOL_ID,
  },
  auth: {
    aws_cognito_region: process.env.REACT_APP_AMPLIFY_REGION,
    aws_user_pools_id: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    aws_user_pools_web_client_id:
      process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: `${process.env.REACT_APP_PUBLIC_SITE_URL}/auth/callback`,
      redirectSignOut: `${process.env.REACT_APP_PUBLIC_SITE_URL}/`,
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
  },
}

export default config
