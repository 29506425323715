import React, {useEffect, useMemo, useState} from 'react'
import axios from 'axios'
import isEqual from 'lodash/isEqual'
import {useParams, useHistory} from 'react-router-dom'
import {Button, TablePagination, Typography} from '@material-ui/core'

import {useAuth} from 'auth'
import config from 'config'

import OrderWidget from '../organisms/OrderWidget'
import DayOrderStats from '../organisms/DayOrderStats'
import useDownload from '../../hooks/useDownload'
import {PRODUCTION_STATUS_TO_DO} from "../../constants"
import Loader from "../atoms/Loader"

const Orders = ({payStatus, productionStatusFilters = [], title, type}) => {
  const params = useParams()

  const area = params.area
  const page = params.page ? Number(params.page) : 0
  const rowsPerPage = params.rowsPerPage ? Number(params.rowsPerPage) : 10

  const isB2C = area === 'b2c'
  const customerTypes = useMemo(() => area === 'b2c' ? ['B2C'] : ['B2B'], [area])
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [reloadProgression, setReloadProgression] = useState(0)
  const [state, setState] = useState({
    isInit: false,
    totalItems: 0,
    orders: [],
    rowsPerPage,
    payStatus,
    productionStatusFilters,
    customerTypes
  })

  const history = useHistory()
  const [isDownloading, setIsDownloading] = useState(false)
  const {download} = useDownload()

  const {currentSession, getAccessToken} = useAuth()

  function handleChangePage(event, newPage) {
    history.push(`/${area}/${type}/${newPage}/${rowsPerPage}`)
  }

  function handleChangeRowsPerPage(event) {
    history.push(`/${area}/${type}/0/${parseInt(event.target.value, 10)}`)
  }

  useEffect(() => {
    console.log('Effect')
    const run = async () => {
      if (!currentSession) {
        return
      }

      setIsLoading(true)
      setHasError(false)

      try {
        const result = await axios.get(
          `${config.serverUrl}orders?limit=${rowsPerPage}&offset=${
            page * rowsPerPage
          }&payStatus=${payStatus}&productionStatusFilters=${encodeURI(
            JSON.stringify(productionStatusFilters)
          )}&customerTypes=${encodeURI(
            JSON.stringify(customerTypes)
          )}`,
          {
            headers: {
              Authorization: `bearer ${currentSession.idToken.getJwtToken()}`,
            },
          }
        )

        setState((s) => ({
          ...s,
          totalItems: result.data.data.totalItems,
          orders: result.data.data.items,
          isInit: true,
        }))
      } catch (e) {
        console.log('------------------ ERRORE CARICAMENTO LISTE ---------------------')
        console.log(e)
        console.log('------------------ FINE ERRORE CARICAMENTO LISTE ---------------------')
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    }

    run()
  }, [
    currentSession,
    page,
    rowsPerPage,
    type,
    state.payStatus,
    state.productionStatusFilters,
    payStatus,
    productionStatusFilters,
    reloadProgression,
    area
  ])

  const handleDownloadCover = async () => {
    setIsDownloading(true)

    try {
      const ginIds = state.orders.map((order) => order.ginId)

      const accessToken = await getAccessToken()

      const result = await axios.post(
        `${config.labelsApiUrl}download-label-pdf-zip`,
        {
          ginIds,
        },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      )

      download(config.staticsPublicBaseUrl + result.data.key, 'etichette.zip')
    } catch (e) {
      console.error(e)
    }
    setIsDownloading(false)
  }

  const handleDownloadCertificates = async () => {
    setIsDownloading(true)

    try {
      const ginIds = state.orders.map((order) => order.ginId)

      const accessToken = await getAccessToken()

      const result = await axios.post(
        `${config.labelsApiUrl}download-certificate-pdf-zip`,
        {
          ginIds,
        },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      )

      download(config.staticsPublicBaseUrl + result.data.key, 'certificati.zip')
    } catch (e) {
      console.error(e)
    }
    setIsDownloading(false)
  }

  const handleSetOrder = (order) => {
    setState((s) => ({
      ...s,
      orders: s.orders.map((orderItem) => {
        if (orderItem.ginId === order.ginId) {
          return order
        }

        return {
          ...orderItem,
          relatedProducts: orderItem.relatedProducts?.map((product) =>
            product.ginId === order.ginId ? order : product
          ),
        }
      }),
    }))
  }

  if (isLoading) {
    return <Loader/>
  }

  if (hasError) {
    return <div style={{textAlign: 'center'}}>
      <Typography variant="h2" gutterBottom>
        Errore
      </Typography>
      <Typography variant="h4" style={{marginBottom: '30px'}}>
        E' stato riscontrato un errore nella fase di caricamento
      </Typography>
      <Button onClick={() => setReloadProgression(s => s + 1)} size="large" variant="outlined">Ricarica</Button>
    </div>
  }
  return (
    <div>
      <Typography variant="h1">{title}</Typography>
      {isB2C && productionStatusFilters.includes(PRODUCTION_STATUS_TO_DO) && <>
        <Button
          variant="outlined"
          onClick={handleDownloadCover}
          disabled={isDownloading}
        >
          Scarica tutte le etichette
        </Button>
        <Button
          variant="outlined"
          onClick={handleDownloadCertificates}
          disabled={isDownloading}
        >
          Scarica tutti i certificati
        </Button>
        <div>
          <DayOrderStats/>
        </div>
      </>}
      <table>
        <tbody>
        <tr>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={state.totalItems}
            rowsPerPage={Number(rowsPerPage)}
            page={Number(page)}
            SelectProps={{
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </tr>
        </tbody>
      </table>

      {state.orders.map((order) => (
        <OrderWidget
          key={order.ginId}
          order={order}
          payStatus={state.payStatus}
          productionStatusFilters={state.productionStatusFilters}
          setOrder={handleSetOrder}
          isB2B={!isB2C}
        />
      ))}
    </div>
  )
}

export default Orders
