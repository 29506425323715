import querystring from 'querystring'

import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Container from './Container'
import SignIn from './SignIn'
import SignUp from './SignUp'
import ConfirmResetPassword from './ConfirmResetPassword'
import ConfirmSignUp from './ConfirmSignUp'
import PasswordForgot from './PasswordForgot'
import ResetPasswordSuccess from './ResetPasswordSuccess'
import SignUpSuccess from './SignUpSuccess'

const authErrorMapping = {
  'Username cannot be empty': 'UsernameEmpty',
  'Password cannot be empty': 'PasswordEmpty',
  'Confirmation code cannot be empty': 'ConfirmationCodeEmpty',
}

const components = {
  signIn: SignIn,
  signUp: SignUp,
  confirmResetPassword: ConfirmResetPassword,
  confirmSignUp: ConfirmSignUp,
  passwordForgot: PasswordForgot,
  resetPasswordSuccess: ResetPasswordSuccess,
  signUpSuccess: SignUpSuccess,
}

const Login = ({ redirectUrl }) => {
  const [view, setView] = useState('signIn')
  const [error, setError] = useState()
  const [loginError, setLoginError] = useState()
  const [fetching, setFetching] = useState(false)
  const [from, setFrom] = useState()
  const [customParams, setCustomParams] = useState()
  const [state, setState] = useState({
    email: '',
    password: '',
    rememberMe: false,
    passwordConfirm: '',
    acceptPrivacyAndTerms: false,
    acceptCommercials: false,
  })
  const location = useLocation()

  const queryParams = useMemo(
    () => querystring.parse((location.search.split('?') || [null, ''])[1]),
    [location]
  )

  const handleSetView = (view) => {
    setLoginError()
    setError()
    setView(view)
  }
  useEffect(() => {
    if (queryParams.error) {
      setLoginError(queryParams.error)
    }
  }, [queryParams?.error, setLoginError])

  useEffect(() => {
    if (queryParams.from) {
      setFrom(queryParams.from)
      setCustomParams(
        Object.keys(queryParams)
          .map(
            (k) =>
              `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k])}`
          )
          .join('&')
      )
    }
  }, [queryParams])

  const Component = components[view]
  return (
    <Container
      view={view}
      setView={handleSetView}
      from={from}
      setState={setState}
      footerProps={Component.footerProps}
    >
      <Component
        redirectUrl={redirectUrl}
        setView={setView}
        state={state}
        setState={setState}
        error={error}
        loginError={loginError}
        setLoginError={setLoginError}
        setError={setError}
        fetching={fetching}
        setFetching={setFetching}
        authErrorMapping={authErrorMapping}
        customUrlParams={customParams}
      />
    </Container>
  )
}

export default Login
