import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Toolbar, IconButton, Typography } from '@material-ui/core'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ArrowRight from '@material-ui/icons/ArrowRight'
import ArrowLeft from '@material-ui/icons/ArrowLeft'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  rowPerPageButton: {
    fontSize: '12px',
    minWidth: '30px',
    marginLeft: '10px',
    padding: '0px',
  },
  inner: { display: 'flex', alignItems: 'center' },
}))
const GinDetailsPagination = ({ next, prev, onNavigateTo }) => {
  const classes = useStyles()

  return (
    <Toolbar className={classes.container}>
      {prev?.numbers ? (
        <Button
          aria-label="Gin Precedente"
          variant="outlined"
          color="default"
          onClick={() => {
            onNavigateTo(prev.id)
          }}
        >
          {prev.numbers?.join(',')}
        </Button>
      ) : (
        <div />
      )}

      {next?.numbers ? (
        <Button
          aria-label="Pagina successiva"
          color="default"
          variant="outlined"
          onClick={() => {
            onNavigateTo(next.id)
          }}
        >
          {next.numbers?.join(',')}
        </Button>
      ) : (
        <div />
      )}
    </Toolbar>
  )
}

export default GinDetailsPagination
