import React from 'react'
import {
  Box,
  Checkbox,
  Typography,
  TextField,
  Button,
  useTheme,
  FormControlLabel,
} from '@material-ui/core'
import { useTranslation, Trans } from 'react-i18next'
import { useAuth } from 'auth'

const SignUp = ({
  state,
  setState,
  error,
  fetching,
  setError,
  setFetching,
  authErrorMapping,
  setView,
}) => {
  const { t } = useTranslation('auth')
  const { AmplifyAuth } = useAuth()
  const theme = useTheme()

  const handleInputChange = (event) => {
    setState((s) => ({ ...s, [event.target.name]: event.target.value }))
  }

  const handleSignUp = async () => {
    setError(null)
    if (!state.acceptPrivacyAndTerms) {
      setError('ShouldAcceptPrivacy')
      return
    }
    if (state.password !== state.passwordConfirm) {
      setError('PasswordsNotEqual')
      return
    }

    setFetching(true)
    try {
      const result = await AmplifyAuth.signUp({
        username: state.email,
        password: state.password,
        attributes: {
          'custom:accept_commercials': state.acceptCommercials
            ? 'true'
            : 'false',
        },
      })

      if (result.userConfirmed === true) {
        setView('signIn')
      } else {
        setView('confirmSignUp')
      }
    } catch (e) {
      if (e.name === 'AuthError') {
        setError(authErrorMapping[e.log] || e.log)
      } else {
        setError(e.name)
      }
    } finally {
      setFetching(false)
    }
  }

  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={[3]}
      pt={6}
    >
      <TextField
        placeholder={t('attributes.email.placeholder')}
        name="email"
        label={t('attributes.email.label')}
        fullWidth
        value={state.email}
        onChange={handleInputChange}
        disabled={fetching}
      />
      <Box mb={5} />
      <TextField
        placeholder={t('attributes.password.placeholder')}
        label={t('attributes.password.label')}
        fullWidth
        type="password"
        name="password"
        value={state.password}
        onChange={handleInputChange}
        disabled={fetching}
      />
      <Box mb={5} />
      <TextField
        placeholder={t('attributes.passwordConfirm.placeholder')}
        label={t('attributes.passwordConfirm.label')}
        type="password"
        fullWidth
        name="passwordConfirm"
        value={state.passwordConfirm}
        onChange={handleInputChange}
        disabled={fetching}
      />
      <Box mb={5} />
      <FormControlLabel
        label={
          <Trans
            t={t}
            i18nKey="attributes.acceptPrivacyAndTerms.label"
            components={{
              privacy: (
                <a
                  href="/privacy-policy"
                  target="_blank"
                  style={{ color: theme.palette.primary.light }}
                />
              ),
              terms: (
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  style={{ color: theme.palette.primary.light }}
                />
              ),
            }}
          />
        }
        control={
          <Checkbox
            name="acceptPrivacyAndTerms"
            onClick={handleInputChange}
            checked={state.acceptPrivacyAndTerms}
            disabled={fetching}
          />
        }
      />

      <Box mb={2} />
      <FormControlLabel
        label={t('attributes.acceptCommercials.label')}
        control={
          <Checkbox
            name="acceptCommercials"
            onClick={handleInputChange}
            checked={state.acceptCommercials}
            disabled={fetching}
          />
        }
      />
      <Box mb={5} />
      <Button
        color="primary"
        onClick={handleSignUp}
        disabled={fetching}
        variant="contained"
      >
        {t('buttons.signUp')}
      </Button>
      {error && (
        <Typography
          align="center"
          p={[3]}
          style={{ color: theme.palette.error.light }}
        >
          <Trans t={t} values={state} i18nKey={`errors.${error}`} />
        </Typography>
      )}
      <Box
        my={3}
        sx={{ borderBottom: '1px solid #ccc', height: '2px', width: '100%' }}
      />
      <Typography align="center" p={[3]}>
        <Trans
          t={t}
          i18nKey="messagePrivacyAndTerms"
          components={{
            privacy: (
              <a
                href={t('urls.privacyPolicy')}
                target="_blank"
                style={{ color: theme.palette.primary.light }}
                rel="noreferrer"
              />
            ),
            terms: (
              <a
                href={t('urls.termsAndCondition')}
                target="_blank"
                style={{ color: theme.palette.primary.light }}
                rel="noreferrer"
              />
            ),
          }}
        />
      </Typography>
    </Box>
  )
}

SignUp.footerProps = {
  message: 'messageSignIn',
  goto: 'signIn',
  resetForm: {
    confirmCode: '',
    password: '',
    passwordConfirm: '',
  },
}

export default SignUp
