import React from 'react'

const LoginFlowTemplate = ({ children }) => (
  <div
    style={{
      margin: '10px',
      border: '1px solid black',
    }}
  >
    {children}
  </div>
)

export default LoginFlowTemplate
