import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Card, TextField, Button} from '@material-ui/core'
import {useAuth} from 'auth'
import axios from 'axios'

import config from '../../config'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 40,
    marginBottom: 10,
  },
  container: {
    display: 'flex',
    alignItems: "flex-end",
  }
}))

const ProgressionPicker = ({progressionId, onSetValue}) => {
  const classes = useStyles()

  const handleSubmit = (e) => {
    e.preventDefault()
    const newValue = e.target.elements[0].value
    if(!newValue || isNaN(Number(newValue))) {
      alert("E' necessario inserire un valore numerico")
      return
    }

    onSetValue(newValue)
  }

  return (
    <Card className={classes.card}>
      <form onSubmit={handleSubmit}>
        <div className={classes.container}>
          <TextField
            placeholder="Codice bottiglia"
            label="Codice"
            name="codice"
            defaultValue={progressionId}
            variant="outlined"
          />
          <Button type='submit' variant="outlined" style={{marginLeft: '30px'}}>Carica</Button>
        </div>
      </form>
    </Card>
  )
}

export default ProgressionPicker
