import React from 'react'
import {ThemeProvider} from '@material-ui/core'
import {I18n} from 'aws-amplify'

import theme from './theme'
import MainRouter from './MainRouter'
import './i18n'
import './App.css'
import SnackbarProvider from './components/organisms/Snackbar'
import {AuthContextProvider} from './auth'
import {BuilderConfigContextProvider} from "./hooks/useBuilderConfig"

I18n.setLanguage('it')

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <SnackbarProvider>
            <BuilderConfigContextProvider>
              <MainRouter/>
            </BuilderConfigContextProvider>
          </SnackbarProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
