import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

export default function HomePage() {
  const { t } = useTranslation()
  return (
    <div className="content-container">
      <Typography variant="h3">{t('home.title')}</Typography>
      {t('home.content')}
    </div>
  )
}
