import React from 'react'
import {makeStyles} from '@material-ui/styles'
import {Button, Toolbar, IconButton, Typography} from '@material-ui/core'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ArrowRight from '@material-ui/icons/ArrowRight'
import ArrowLeft from '@material-ui/icons/ArrowLeft'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  rowPerPageButton: {
    fontSize: '12px',
    minWidth: '30px',
    marginLeft: '10px',
    padding: '0px'
  },
  inner: {display: 'flex', alignItems: 'center'}
}))
const TablePagination = ({
                           rowsPerPageOptions,
                           colSpan,
                           count,
                           rowsPerPage,
                           page,
                           onPageChange,
                           onRowsPerPageChange,
                         }) => {
  const classes = useStyles()
  // const { className, message, onClose, variant, ...other } = props
  // const Icon = variantIcon[variant]

  const totalPages =
    Math.floor(count / rowsPerPage) + ((count % rowsPerPage) > 0 ? 1 : 0)
  const currentPage =
    Number(page)

  return (
    <Toolbar className={classes.container}>
      <IconButton
        aria-label="Pagina Precedente"
        color="default"
        onClick={() => {
          onPageChange(0)
        }}
      >
        <FirstPage style={{fontSize: 45}}/>
      </IconButton>
      <div className={classes.inner}>
        <IconButton
          aria-label="Pagina Precedente"
          color="default"
          disabled={currentPage === 0}
          onClick={() => {
            onPageChange(Number(page) - 1)
          }}
        >
          <ArrowLeft style={{fontSize: 45}}/>
        </IconButton>
        <Typography>Pagina {currentPage} di {totalPages} ({count} ordini)</Typography>
        <IconButton
          aria-label="Pagina successiva"
          color="default"
          disabled={currentPage === totalPages - 1}
          onClick={() => {
            onPageChange(Number(page) + 1)
          }}
        >
          <ArrowRight style={{fontSize: 45}}/>
        </IconButton>
        {rowsPerPageOptions.map((item) => (
          <Button
            key={item}
            size='small'
            className={classes.rowPerPageButton}
            variant={item === rowsPerPage ? 'contained' : 'outlined'}
            color={item === rowsPerPage ? 'primary' : ''}
            onClick={() => {
              onRowsPerPageChange(item)
            }}
          >
            {item}
          </Button>
        ))}
      </div>
      <IconButton
        aria-label="Pagina successiva"
        color="default"
        onClick={() => {
          onPageChange(totalPages - 1)
        }}
      >
        <LastPage style={{fontSize: 45}}/>
      </IconButton>
    </Toolbar>
  )
}

export default TablePagination
