import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { I18n as AmplifyI18n } from 'aws-amplify'

import commonIt from './translations/it/translation.json'
import authIt from './auth/locale/it.json'

const defaultLanguage = 'it'

AmplifyI18n.setLanguage(defaultLanguage)

i18n.use(initReactI18next).init({
  resources: {
    it: {
      translation: commonIt,
      auth: authIt,
    },
  },
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
