import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, TextField, Button, useTheme } from '@material-ui/core'
import { useAuth } from 'auth'

const PasswordForgot = ({
  setView,
  state,
  setState,
  error,
  setError,
  setFetching,
  fetching,
  authErrorMapping,
}) => {
  const theme = useTheme()
  const { t } = useTranslation('auth')
  const { AmplifyAuth } = useAuth()

  const handleInputChange = (event) => {
    setState((s) => ({ ...s, [event.target.name]: event.target.value }))
  }

  const handlePasswordForgot = async (event) => {
    try {
      setFetching(true)
      setError(null)
      await AmplifyAuth.forgotPassword(state.email)
      setView('confirmResetPassword')
    } catch (e) {
      if (e.name === 'AuthError') {
        setError(authErrorMapping[e.log] || e.log)
        return
      }
      if (e.code === 'CodeMismatchException') {
        setError(e.code)
        return
      }
      console.log(e)
      setError(e.code)
    } finally {
      setFetching(false)
    }
  }

  return (
    <Box
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={[3]}
      pt={3}
    >
      <Typography
        align="center"
        style={{ color: theme.palette.error.dark }}
        mb={5}
      >
        {t('messagePasswordForgot', state)}
      </Typography>
      <TextField
        label={t('attributes.email.label')}
        placeholder={t('attributes.email.placeholder')}
        name="email"
        fullWidth
        value={state.email}
        onChange={handleInputChange}
        disabled={fetching}
      />
      <Box mb={5} />
      <Button
        color="primary"
        variant="contained"
        disabled={fetching}
        onClick={handlePasswordForgot}
      >
        {t('buttons.passwordForgot')}
      </Button>
      {error && (
        <Typography
          align="center"
          p={[3]}
          style={{ color: theme.palette.error.light }}
        >
          {t(`errors.${error}`, state)}
        </Typography>
      )}
    </Box>
  )
}

PasswordForgot.footerProps = {
  message: 'messageChangeAccount',
  goto: 'signIn',
  resetForm: {
    confirmCode: '',
    password: '',
    passwordConfirm: '',
  },
}

export default PasswordForgot
