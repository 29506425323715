import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'

import config from 'config'

const useStyle = makeStyles(() => ({
  fullList: {
    width: 'auto',
    paddingBottom: '40px',
  },
  subList: {
    '& .MuiListItem-root': {
      paddingBottom: '2px',
      paddingTop: '2px',
    },
  },
}))

const Drawer = () => {
  const classes = useStyle()
  const { area } = useParams()
  return (
    <div className={classes.fullList}>
      <List>
        {area === 'b2c' && (
          <>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2c/to-verify-orders`}
            >
              <ListItemText primary="Da verificare" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2c/verified-orders`}
            >
              <ListItemText primary="Verificati" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2c/to-do-orders`}
            >
              <ListItemText primary="Da fare" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2c/to-do-orders-p1`}
            >
              <ListItemText primary="Da fare P1" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2c/to-do-orders-p2`}
            >
              <ListItemText primary="Da fare P2" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2c/done-orders`}
            >
              <ListItemText primary="Fatti" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2c/sent-orders`}
            >
              <ListItemText primary="Spediti" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2c/delivered-orders`}
            >
              <ListItemText primary="Consegnati" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2c/missing-label-orders`}
            >
              <ListItemText primary="No Etichetta" />
            </ListItem>
          </>
        )}
        {area === 'b2b' && (
          <>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2b/to-verify-orders`}
            >
              <ListItemText primary="Da verificare" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2b/verified-orders`}
            >
              <ListItemText primary="Verificati" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2b/to-do-orders`}
            >
              <ListItemText primary="Da fare" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2b/done-orders`}
            >
              <ListItemText primary="Fatti" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2b/sent-orders`}
            >
              <ListItemText primary="Spediti" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}b2b/delivered-orders`}
            >
              <ListItemText primary="Consegnati" />
            </ListItem>
          </>
        )}
        {area === 'external' && (
          <>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}external/in-progress`}
            >
              <ListItemText primary="In corso" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={`${config.baseUrl}external/completed`}
            >
              <ListItemText primary="Completati" />
            </ListItem>
          </>
        )}
      </List>
    </div>
  )
}
export default Drawer
