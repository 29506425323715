import { CART_STATE_CHECKED_OUT, CART_STATE_PAID } from '../constants'

function getCartState(order) {
  switch (order.cartState) {
    case CART_STATE_PAID:
      return CART_STATE_PAID
    case CART_STATE_CHECKED_OUT:
      return CART_STATE_CHECKED_OUT
  }
}

export default getCartState
