import React, {useEffect, useMemo, useState} from 'react'
import axios from 'axios'
import {useParams, useHistory} from 'react-router-dom'
import {Button, Typography} from '@material-ui/core'
import {useAuth} from 'auth'
import config from 'config'

import TablePagination from '../molecules/TablePagination'
import OrderWidgetP1 from '../organisms/OrderWidgetP1'
import useDownload from '../../hooks/useDownload'
import Loader from "../atoms/Loader"

const Orders = ({payStatus, productionStatusFilters = [], showCapsule, title, type}) => {
  const params = useParams()

  const area = params.area
  const page = params.page ? Number(params.page) : 0
  const rowsPerPage = params.rowsPerPage ? Number(params.rowsPerPage) : 10

  const isB2C = area === 'b2c'
  const customerTypes = useMemo(() => area === 'b2c' ? ['B2C'] : ['B2B'], [area])
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [reloadProgression, setReloadProgression] = useState(0)
  const [state, setState] = useState({
    isInit: false,
    totalItems: 0,
    orders: [],
    rowsPerPage,
    payStatus,
    productionStatusFilters,
    customerTypes
  })

  const history = useHistory()

  const {currentSession} = useAuth()

  function handleChangePage(newPage) {
    history.push(`/${area}/${type}/${newPage}/${rowsPerPage}`)
  }

  function handleChangeRowsPerPage(rowPerPage) {
    history.push(`/${area}/${type}/0/${parseInt(rowPerPage, 10)}`)
  }

  useEffect(() => {
    const run = async () => {
      if (!currentSession) {
        return
      }

      setIsLoading(true)
      setHasError(false)

      try {
        const result = await axios.get(
          `${config.serverUrl}orders?limit=${rowsPerPage}&offset=${
            page * rowsPerPage
          }&payStatus=${payStatus}&productionStatusFilters=${encodeURI(
            JSON.stringify(productionStatusFilters)
          )}&customerTypes=${encodeURI(
            JSON.stringify(customerTypes)
          )}`,
          {
            headers: {
              Authorization: `bearer ${currentSession.idToken.getJwtToken()}`,
            },
          }
        )

        setState((s) => ({
          ...s,
          totalItems: result.data.data.totalItems,
          orders: result.data.data.items,
          isInit: true,
        }))
      } catch (e) {
        console.log('------------------ ERRORE CARICAMENTO LISTE ---------------------')
        console.log(e)
        console.log('------------------ FINE ERRORE CARICAMENTO LISTE ---------------------')
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    }

    run()
  }, [
    currentSession,
    page,
    rowsPerPage,
    type,
    state.payStatus,
    state.productionStatusFilters,
    payStatus,
    productionStatusFilters,
    reloadProgression,
    area
  ])

  const handleSetOrder = (order) => {
    setState((s) => ({
      ...s,
      orders: s.orders.map((orderItem) => {
        if (orderItem.ginId === order.ginId) {
          return order
        }

        return {
          ...orderItem,
          relatedProducts: orderItem.relatedProducts?.map((product) =>
            product.ginId === order.ginId ? order : product
          ),
        }
      }),
    }))
  }

  if (isLoading) {
    return <Loader/>
  }

  if (hasError) {
    return <div style={{textAlign: 'center'}}>
      <Typography variant="h2" gutterBottom>
        Errore
      </Typography>
      <Typography variant="h4" style={{marginBottom: '30px'}}>
        E' stato riscontrato un errore nella fase di caricamento
      </Typography>
      <Button onClick={() => setReloadProgression(s => s + 1)} size="large" variant="outlined">Ricarica</Button>
    </div>
  }
  return (
    <div>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        colSpan={3}
        count={state.totalItems}
        rowsPerPage={Number(rowsPerPage)}
        page={Number(page)}
        SelectProps={{
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {state.orders.map((order) => (
        <OrderWidgetP1
          key={order.ginId}
          order={order}
          payStatus={state.payStatus}
          showCapsule={showCapsule}
          productionStatusFilters={state.productionStatusFilters}
          setOrder={handleSetOrder}
          isB2B={!isB2C}
        />
      ))}

      <TablePagination
        rowsPerPageOptions={[10, 25]}
        colSpan={3}
        count={state.totalItems}
        rowsPerPage={Number(rowsPerPage)}
        page={Number(page)}
        SelectProps={{
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default Orders
