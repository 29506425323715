import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Button, useTheme } from '@material-ui/core'

const SignInSuccess = ({ setView, state }) => {
  const theme = useTheme()
  const { t } = useTranslation('auth')

  const handleSignUpSuccess = async (event) => {
    setView('signIn')
  }

  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={[3]}
      pt={6}
      width="100%"
    >
      <Typography
        align="center"
        style={{ color: theme.palette.error.dark }}
        mb={5}
        fontSize={5}
      >
        {t('messageSignUpSuccess', state)}
      </Typography>
      <Box mb={5} />
      <Button color="primary" variant="contained" onClick={handleSignUpSuccess}>
        {t('buttons.signUpSuccess')}
      </Button>
    </Box>
  )
}

export default SignInSuccess
