import React, { useEffect, useState } from 'react'
import amplifyCore from '@aws-amplify/core'
import amplifyAuth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

import config from 'config'

export const AuthContext = React.createContext('auth')

amplifyCore.configure(config.auth)

export const AuthContextProvider = ({ children }) => {
  const [authEvent, setAuthEvent] = useState()
  const [currentSession, setSession] = useState()

  useEffect(() => {
    const run = async () => {
      updateAuth()
    }
    run()
  }, [setAuthEvent])

  const signOut = () => {
    amplifyAuth.signOut()
    setTimeout(() => {
      setAuthEvent()
      setSession()
    }, 100)
  }

  const updateAuth = async () => {
    if (!amplifyAuth) {
      return
    }
    try {
      const auth = await amplifyAuth.currentAuthenticatedUser()

      const session = await amplifyAuth.currentSession()
      setAuthEvent(auth)
      setSession(session)
    } catch (e) {
      console.error(e)
      setAuthEvent(null)
    }
  }

  const getAccessToken = async () => {
    return (await amplifyAuth.currentSession())?.idToken.jwtToken
  }

  return (
    <AuthContext.Provider
      value={{
        authEvent,
        currentSession,
        updateAuth,
        AmplifyCore: amplifyCore,
        AmplifyAuth: amplifyAuth,
        signOut,
        getAccessToken,
        CognitoHostedUIIdentityProvider
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
