const useDownload = () => {
  function download(imageUrl, name) {
    const a = document.createElement('a')
    a.href = imageUrl
    a.target = '_new'
    a.download = name || 'image.png'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(imageUrl)
  }

  return {
    download,
  }
}

export default useDownload
