import React from 'react'

import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import CustomerData from '../../molecules/CustomerData'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    marginBottom: 30,
  },
}))

const Customer = ({ order, hideBilling, hideShipping }) => {
  const classes = useStyles()

  if (!order) {
    return false
  }

  return (
    <>
      {!hideBilling && (
        <Paper elevation={3} className={classes.root}>
          <CustomerData data={order.billingAddress} isBilling />
        </Paper>
      )}
      {!hideShipping && (
        <Paper elevation={3} className={classes.root}>
          <CustomerData data={order.shippingAddress} isShipping />
        </Paper>
      )}
    </>
  )
}

export default Customer
