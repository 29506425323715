import { useContext } from 'react'

import { AuthContext } from './AuthContext'

export function useAuth() {
  const {
    authEvent,
    updateAuth,
    authInitialized,
    AmplifyCore,
    AmplifyAuth,
    CognitoHostedUIIdentityProvider,
    signOut,
    currentSession,
    getAccessToken,
  } = useContext(AuthContext)

  return {
    authEvent,
    updateAuth,
    authInitialized,
    AmplifyCore,
    AmplifyAuth,
    CognitoHostedUIIdentityProvider,
    signOut,
    currentSession,
    getAccessToken,
  }
}
