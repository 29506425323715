import React, {useContext, useEffect, useState} from 'react'

import config from 'config'
import axios from "axios"

import {AuthContext} from "../auth"

export const BuilderConfigContext = React.createContext('builder-config')

export const BuilderConfigContextProvider = ({children}) => {
  const [builderConfig, setBuilderConfig] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const run = async () => {
      const result = await axios.get(`${config.configApiUrl}builder-config`)
      setBuilderConfig(result.data.body)
      setIsLoading(false)
    }
    run()
  }, [])

  return (
    <BuilderConfigContext.Provider
      value={{
        builderConfig,
      }}
    >
      {
        isLoading
          ? 'Loading...'
          : children
      }
    </BuilderConfigContext.Provider>
  )
}

export function useBuilderConfig() {
  const {
    builderConfig,
  } = useContext(BuilderConfigContext)

  const getCustomizationLabel = (type, id) => {
    return builderConfig?.stepsConfig?.steps?.find(
      item => item.id === type)?.models.find(model => model.id === id)?.name || id
  }

  return {
    builderConfig,
    getCustomizationLabel
  }
}
