import React from 'react'
import {Typography} from '@material-ui/core'

import config from "../../config"
import {useBuilderConfig} from "../../hooks/useBuilderConfig"

const Customizations = ({order}) => {
  const {getCustomizationLabel} = useBuilderConfig()
  if (!order.customizations) {
    return
  }

  const mapKey = {
    bottle: 'Bottiglia',
    cork: 'Tappo',
    coloring: 'Colorazione',
    capsule: 'Capsula',
    alcohol_content: 'Gradazione',
  }

  return (
    <Typography variant="h2" color="textPrimary">
      Customizzazioni:&nbsp;&nbsp;&nbsp;
      {Object.entries(order.customizations || [])
        .map(([key, value]) =>
          <Typography variant="h4" color="textPrimary" key={key}>
            {mapKey[key] || key}: <strong>{getCustomizationLabel(key, value.id)}</strong>
          </Typography>)}
    </Typography>)
}

export default Customizations
