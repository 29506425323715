import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, TextField, Button, useTheme } from '@material-ui/core'
import { useAuth } from 'auth'

const ConfirmSignUp = ({
  setView,
  state,
  setState,
  error,
  setError,
  setFetching,
  fetching,
  authErrorMapping,
}) => {
  const theme = useTheme()
  const { t } = useTranslation('auth')
  const { AmplifyAuth } = useAuth()
  const [localMessage, setLocalMessage] = useState()

  const handleInputChange = (event) => {
    setState((s) => ({ ...s, [event.target.name]: event.target.value }))
  }

  const handleConfirmSignUp = async (event) => {
    try {
      setFetching(true)
      setError(null)
      await AmplifyAuth.confirmSignUp(state.email, state.confirmCode)
      setView('signUpSuccess')
    } catch (e) {
      if (e.name === 'AuthError') {
        setError(authErrorMapping[e.log] || e.log)
      }
      if (e.code === 'CodeMismatchException') {
        setError(e.code)
      }
      console.log(e)
      setError(e.code)
    }
    setFetching(false)
  }

  const handleResendCode = async () => {
    try {
      setFetching(true)
      setError(null)
      setLocalMessage(null)
      await AmplifyAuth.resendSignUp(state.email)
      setLocalMessage('CodeSentSuccessfully')
    } catch (e) {
      console.log(e)
      if (
        e.code === 'InvalidParameterException' &&
        e.message.includes('already confirmed')
      ) {
        setError('UserAlreadyConfirmed')
      } else {
        setError(e.code || e.log)
      }
    } finally {
      setFetching(false)
    }
  }

  return (
    <Box
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={[3]}
      pt={3}
    >
      <Typography
        align="center"
        style={{ color: theme.palette.primary.dark }}
        mb={5}
      >
        {t('messageConfirmSignUp', state)}
      </Typography>
      <TextField
        placeholder={t('attributes.confirmCode.placeholder')}
        label={t('attributes.confirmCode.label')}
        name="confirmCode"
        value={state.confirmCode}
        onChange={handleInputChange}
        disabled={fetching}
      />
      <Box mb={5} />
      <Button
        color="primary"
        variant="contained"
        disabled={fetching}
        onClick={handleConfirmSignUp}
      >
        {t('buttons.confirmSignUp')}
      </Button>
      {error && (
        <Typography
          align="center"
          p={[3]}
          style={{ color: theme.palette.error.light }}
        >
          {t(`errors.${error}`, state)}
        </Typography>
      )}

      <Box
        my={3}
        sx={{ borderBottom: '1px solid #ccc', height: '2px', width: '100%' }}
      />

      <Button color="secondary" disabled={fetching} onClick={handleResendCode}>
        {t('buttons.resendConfirmCode')}
      </Button>
      {localMessage && (
        <Typography
          align="center"
          p={[3]}
          style={{ color: theme.palette.error.light }}
        >
          {t(`errors.${localMessage}`, state)}
        </Typography>
      )}
    </Box>
  )
}

ConfirmSignUp.footerProps = {
  message: 'messageChangeAccount',
  goto: 'signIn',
  resetForm: {
    confirmCode: '',
    password: '',
    passwordConfirm: '',
  },
}

export default ConfirmSignUp
