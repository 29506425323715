import React, { useEffect, useState, useRef, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import ArrowRight from '@material-ui/icons/ArrowRight'
import ArrowLeft from '@material-ui/icons/ArrowLeft'

import { useAuth } from 'auth'

import axios from 'axios'

import config from 'config'

import { useHistory, useParams } from 'react-router-dom'

import * as PropTypes from 'prop-types'

import Order from '../organisms/OrderP1'
import Customer from '../organisms/Customer'
import GinDetailPagination from '../molecules/GinDetailPagination'

import {
  PRODUCTION_STATUS_TO_VERIFY,
  PRODUCTION_STATUS_NO_LABEL,
  PRODUCTION_STATUS_VERIFIED,
  PRODUCTION_STATUS_TO_DO,
  PRODUCTION_STATUS_DONE,
  PRODUCTION_STATUS_EXTERNALIZED,
  PRODUCTION_STATUS_COMPLETED_EXTERNAL,
  // PRODUCTION_STATUS_SHIPPED
} from '../../constants'
import getGinState from '../../utils/getGinState'

import isCartReadyToDo from '../../utils/isCartReadyToDo'
import OrderWidgetLight from '../organisms/OrderWidgetLight'
import ProgressionPicker from '../organisms/ProgressionPicker'

class PrintableComponent extends React.Component {
  render() {
    return (
      <>
        <Order {...this.props} />
        <Customer {...this.props} />
      </>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icons: {
    marginLeft: 30,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    '& Button': {
      marginLeft: 10,
    },
  },
}))

function OrderActions({ order, setOrder }) {
  const { currentSession } = useAuth()
  const classes = useStyles()

  const changeStatus = async (status) => {
    if (!window.confirm('Sei sicuro di voler cambiare lo stato?')) {
      return
    }

    const result = await axios.put(
      `${config.serverUrl}orders/${order.ginId}/status/${status}`,
      {},
      {
        headers: {
          Authorization: `bearer ${currentSession.idToken.getJwtToken()}`,
        },
      }
    )

    setOrder(result.data.data.order)
  }

  const ginState = getGinState(order)
  const canGoToDo = isCartReadyToDo(order)

  const goToVerify = () => changeStatus(PRODUCTION_STATUS_TO_VERIFY)
  const goVerified = () => changeStatus(PRODUCTION_STATUS_VERIFIED)
  const goMissingLabel = () => changeStatus(PRODUCTION_STATUS_NO_LABEL)
  const goExternalize = () => changeStatus(PRODUCTION_STATUS_EXTERNALIZED)
  const goCompletedExternal = () =>
    changeStatus(PRODUCTION_STATUS_COMPLETED_EXTERNAL)
  const goToDo = () => changeStatus(PRODUCTION_STATUS_TO_DO)
  const goToDone = () => changeStatus(PRODUCTION_STATUS_DONE)
  // const goSent = () => changeStatus(PRODUCTION_STATUS_SHIPPED)

  return (
    <>
      {ginState === PRODUCTION_STATUS_TO_VERIFY && (
        <div className={classes.buttons}>
          <Button
            // disabled={order.hashChanged}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={goVerified}
          >
            Verificato <ArrowRight />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={goMissingLabel}
          >
            Manca copertina <ArrowRight />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={goExternalize}
          >
            Esternalizza
            <ArrowRight />
          </Button>
        </div>
      )}
      {ginState === PRODUCTION_STATUS_NO_LABEL && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={goToVerify}
          >
            <ArrowLeft /> Da verificare
          </Button>
        </div>
      )}
      {ginState === PRODUCTION_STATUS_VERIFIED && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={goToVerify}
          >
            <ArrowLeft /> Riporta in Da Verificare
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={goToDo}
            disabled={!canGoToDo}
          >
            Da Fare <ArrowRight />
          </Button>
        </div>
      )}
      {ginState === PRODUCTION_STATUS_TO_DO && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={goVerified}
          >
            <ArrowLeft /> Riporta in Verificato
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={goToDone}
          >
            Pronto da spedire <ArrowRight />
          </Button>
        </div>
      )}
      {ginState === PRODUCTION_STATUS_DONE && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={goToDo}
          >
            <ArrowLeft /> Riporta in Da fare
          </Button>
          {/* <Button */}
          {/*  variant="contained" */}
          {/*  color="primary" */}
          {/*  className={classes.button} */}
          {/*  onClick={goSent} */}
          {/* > */}
          {/*  Spedito <ArrowRight /> */}
          {/* </Button> */}
        </div>
      )}
      {ginState === PRODUCTION_STATUS_EXTERNALIZED && (
        <div className={classes.buttons}>
          <Button
            // disabled={order.hashChanged}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={goToVerify}
          >
            Da verificare
            <ArrowRight />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={goCompletedExternal}
          >
            Manda in completato Ext. <ArrowRight />
          </Button>
        </div>
      )}
    </>
  )
}

OrderActions.propTypes = {
  cartState: PropTypes.any,
  ginState: PropTypes.any,
  classes: PropTypes.any,
}

const OrderPageP1 = ({ isByProgression }) => {
  const componentRef = useRef()
  const { orderId, progressionId, area } = useParams()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [order, setOrder] = useState()
  const { currentSession } = useAuth()
  const [currentVersion, setCurentVersion] = useState(0)
  const history = useHistory()

  const handleNewProgressionValue = (newValue) => {
    history.push(`/ordersbyprogression/${newValue}`)
  }

  const handleNewId = (ginId) => {
    history.push(`/${area}/orders-p1/${ginId}`)
  }

  const refreshPage = useCallback(() => {
    setCurentVersion((s) => s + 1)
  }, [])

  useEffect(() => {
    const run = async () => {
      setIsLoading(true)
      if (isByProgression) {
        if (!progressionId) {
          setOrder(null)
          setIsLoading(false)
          return
        }

        try {
          const result = await axios.get(
            `${config.serverUrl}orders/progression/${progressionId}`,
            {
              headers: {
                Authorization: `bearer ${currentSession.idToken.getJwtToken()}`,
              },
            }
          )
          setOrder(result.data.data.order)
        } catch (e) {
          console.log(e)
          setOrder(null)
        }
        setIsLoading(false)
      } else {
        const result = await axios.get(`${config.serverUrl}orders/${orderId}`, {
          headers: {
            Authorization: `bearer ${currentSession.idToken.getJwtToken()}`,
          },
        })

        setOrder(result.data.data.order)
        setIsLoading(false)
      }
    }

    run()
  }, [currentSession, orderId, currentVersion, progressionId])

  if (isLoading) {
    return <div>Loading order</div>
  }

  if (isByProgression && !progressionId) {
    return (
      <>
        <ProgressionPicker
          progressionId={progressionId}
          onSetValue={handleNewProgressionValue}
        />
        <div>Inserire un id ordine</div>
      </>
    )
  }

  if (isByProgression && !order && !isLoading) {
    return (
      <>
        <ProgressionPicker
          progressionId={progressionId}
          onSetValue={handleNewProgressionValue}
        />
        <div>Il gin cercato non esiste</div>
      </>
    )
  }

  if (!order) {
    return
  }

  return (
    <div>
      {isByProgression && (
        <ProgressionPicker
          progressionId={progressionId}
          onSetValue={handleNewProgressionValue}
        />
      )}

      <GinDetailPagination
        prev={order.productionPrev}
        next={order.productionNext}
        onNavigateTo={handleNewId}
      />
      <PrintableComponent
        key={order.orderId}
        order={order}
        ref={componentRef}
        refreshPage={refreshPage}
      />
      <GinDetailPagination
        prev={order.productionPrev}
        next={order.productionNext}
        onNavigateTo={handleNewId}
      />
      {order.relatedProducts?.length > 0 && (
        <Typography variant="h2" color="textSecondary" gutterBottom>
          Prodotti dello stesso ordine
        </Typography>
      )}
      {order.relatedProducts?.map((item) => (
        <OrderWidgetLight key={item.ginId} order={item} hideActions />
      ))}
    </div>
  )
}

export default OrderPageP1
