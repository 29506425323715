import React, {useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Button} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import HomeIcon from '@material-ui/icons/Home'
import SearchIcon from '@material-ui/icons/Search'

import Logout from 'auth/LogoutButton'
import {useHistory} from "react-router-dom"

import config from "../../config"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  env: {
    marginLeft: 10,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 10,
    marginLeft: -10,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}))

const TopBar = ({toggleDrawer}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const history = useHistory()

  const menuId = 'primary-search-account-menu'
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    // handleMobileMenuClose()
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Logout/>
      </MenuItem>
    </Menu>
  )

  return (<>
      <AppBar position="fixed" color="default" className={classes.root}>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={toggleDrawer}
          >
            <MenuIcon/>
          </IconButton>
          <a href="/">
            <HomeIcon/>
          </a>
          <Button href={`/b2c/to-verify-orders`} sx={{color: '#fff'}}>
            Consumer
          </Button>
          <Button href={`/b2b/to-verify-orders`} sx={{color: '#fff'}}>
            Business
          </Button>
          <Button href={`/external/in-progress`} sx={{color: '#fff'}}>
            Esterni
          </Button>
          <Typography variant="h6" color="inherit" className={classes.grow}/>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            href='/ordersbyprogression'
            color="inherit"
          >
            <SearchIcon/>
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircleIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}</>
  )
}

export default TopBar
