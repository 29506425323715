import {
  CART_PAYMENT_MODE_BACS,
  CART_STATE_CHECKED_OUT,
  GIN_STATE_COMPLETED, GIN_STATE_COMPLETED_EXTERNAL,
  GIN_STATE_DONE, GIN_STATE_EXTERNALIZED,
  GIN_STATE_NEW,
  GIN_STATE_NO_LABEL,
  GIN_STATE_SHIPPED,
  GIN_STATE_TO_DO,
  GIN_STATE_TO_VERIFY,
  GIN_STATE_VERIFIED,
  PRODUCTION_STATUS_BANK_TRANSFER,
  PRODUCTION_STATUS_COMPLETED, PRODUCTION_STATUS_COMPLETED_EXTERNAL,
  PRODUCTION_STATUS_DONE, PRODUCTION_STATUS_EXTERNALIZED,
  PRODUCTION_STATUS_NO_LABEL,
  PRODUCTION_STATUS_SHIPPED,
  PRODUCTION_STATUS_TO_DO,
  PRODUCTION_STATUS_TO_VERIFY,
  PRODUCTION_STATUS_VERIFIED,
} from '../constants'

function getGinState(order) {
  switch (order.ginState) {
    case GIN_STATE_NEW:
      if (
        order.paymentMode === CART_PAYMENT_MODE_BACS &&
        order.cartState === CART_STATE_CHECKED_OUT
      ) {
        return PRODUCTION_STATUS_BANK_TRANSFER
      }
      return PRODUCTION_STATUS_TO_VERIFY
    case GIN_STATE_TO_VERIFY:
      return PRODUCTION_STATUS_TO_VERIFY
    case GIN_STATE_NO_LABEL:
      return PRODUCTION_STATUS_NO_LABEL
    case GIN_STATE_VERIFIED:
      return PRODUCTION_STATUS_VERIFIED
    case GIN_STATE_TO_DO:
      return PRODUCTION_STATUS_TO_DO
    case GIN_STATE_DONE:
      return PRODUCTION_STATUS_DONE
    case GIN_STATE_SHIPPED:
      return PRODUCTION_STATUS_SHIPPED
    case GIN_STATE_COMPLETED:
      return PRODUCTION_STATUS_COMPLETED
    case GIN_STATE_EXTERNALIZED:
      return PRODUCTION_STATUS_EXTERNALIZED
    case GIN_STATE_COMPLETED_EXTERNAL:
      return PRODUCTION_STATUS_COMPLETED_EXTERNAL
  }
}

export default getGinState
