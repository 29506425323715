import React from 'react'
import {Box, Button, Typography} from '@material-ui/core'

import axios from 'axios'
import {useAuth} from 'auth'

import config from "../../config"

const MDG = ({order, refreshPage}) => {
  const {getAccessToken} = useAuth()

  if (!order.mdg || !config.flags.mdg) {
    return
  }

  const removeMDG = async () => {
    const accessToken = await getAccessToken()

    await axios.put(
      `${config.serverUrl}mdg/${order.ginId}/use-mdg`,
      {
        newState: false
      },
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
        }
      }
    )

    refreshPage()
  }

  const addMDG = async () => {
    const accessToken = await getAccessToken()

    await axios.put(
      `${config.serverUrl}mdg/${order.ginId}/use-mdg`,
      {
        newState: true
      },
      {
        headers: {
          Authorization: `bearer ${accessToken}`,
        }
      }
    )

    refreshPage()
  }

  return (
    <Box mt={2}>
      <Typography variant="h2" color="textPrimary">
        Usa MDG:&nbsp;{order.mdg.use ? 'Si' : 'No'}
      </Typography>
      {order.mdg.use && <Button onClick={removeMDG}>Rimuovi da MDG</Button>}
      {!order.mdg.use && <Button onClick={addMDG}>Aggiungi a MDG</Button>}
    </Box>
  )
}

export default MDG
