import React, { useState, createContext } from 'react'
import { Snackbar as MuiSnackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import T from 'prop-types'

export const SnackbarContext = createContext()

export default function SnackbarProvider({ children }) {
  const [snackbarData, setSnackbarData] = useState({ open: false })

  const handleSnackbarClose = () =>
    setSnackbarData((state) => ({ ...state, open: false }))

  return (
    <SnackbarContext.Provider value={{ setSnackbarData }}>
      {children}
      <MuiSnackbar
        open={snackbarData.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarData.severity}
        >
          {snackbarData.message || 'Operazione conseguita con successo.'}
        </Alert>
      </MuiSnackbar>
    </SnackbarContext.Provider>
  )
}

SnackbarProvider.propTypes = {
  children: T.node,
}
