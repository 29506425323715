import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Typography } from '@material-ui/core'

const Footer = ({ onClick, messageKey }) => {
  const { t } = useTranslation('auth')
  return (
    <Typography align="center">
      <Trans
        t={t}
        i18nKey={messageKey}
        components={{
          action: <span style={{ cursor: 'pointer' }} onClick={onClick} />,
        }}
      />
    </Typography>
  )
}

export default Footer
