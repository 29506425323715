export const PAYED_STATUS_NOT_PAYED = 'notPayed'
export const PAYED_STATUS_PAYED = 'payed'
export const PAYED_STATUS_DELIVERED = 'delivered'
//
//   - Da verificare
//   - Manca copertina
// - Da Fare
// - Fatti
// - Fatto

export const PRODUCTION_CUSTOMER_TYPE_B2B = 'B2B'
export const PRODUCTION_CUSTOMER_TYPE_B2C = 'B2C'

export const PRODUCTION_STATUS_BANK_TRANSFER = -10
export const PRODUCTION_STATUS_TO_VERIFY = 0
export const PRODUCTION_STATUS_NO_LABEL = 5
export const PRODUCTION_STATUS_VERIFIED = 8
export const PRODUCTION_STATUS_TO_DO = 10
export const PRODUCTION_STATUS_DONE = 20
export const PRODUCTION_STATUS_SHIPPED = 100
export const PRODUCTION_STATUS_COMPLETED = 1000
export const PRODUCTION_STATUS_EXTERNALIZED = 1010
export const PRODUCTION_STATUS_COMPLETED_EXTERNAL = 1020

export const ProductionStatusLabel = {
  [PRODUCTION_STATUS_BANK_TRANSFER]: 'Bonifico',
  [PRODUCTION_STATUS_TO_VERIFY]: 'Da verificare',
  [PRODUCTION_STATUS_NO_LABEL]: 'Manca copertina',
  [PRODUCTION_STATUS_VERIFIED]: 'Verificati',
  [PRODUCTION_STATUS_TO_DO]: 'Da fare',
  [PRODUCTION_STATUS_DONE]: 'Fatto',
  [PRODUCTION_STATUS_SHIPPED]: 'Spedito',
  [PRODUCTION_STATUS_COMPLETED]: 'Completati',
  [PRODUCTION_STATUS_EXTERNALIZED]: 'Esternalizzati',
  [PRODUCTION_STATUS_COMPLETED_EXTERNAL]: 'Completati Ext',
}

export const GIN_STATE_NEW = 'NEW'
export const GIN_STATE_TO_VERIFY = 'TO_VERIFY'
export const GIN_STATE_VERIFIED = 'VERIFIED'
export const GIN_STATE_NO_LABEL = 'NO_LABEL'
export const GIN_STATE_TO_DO = 'TO_DO'
export const GIN_STATE_DONE = 'DONE'
export const GIN_STATE_SHIPPED = 'SHIPPED'
export const GIN_STATE_COMPLETED = 'COMPLETED'
export const GIN_STATE_EXTERNALIZED = 'EXTERNALIZED'
export const GIN_STATE_COMPLETED_EXTERNAL = 'COMPLETED_EXTERNAL'

export const CART_STATE_CHECKED_OUT = 'CHECKED_OUT'
export const CART_STATE_PAID = 'PAID'
export const CART_PAYMENT_MODE_BACS = 'bacs'
export const CART_PAYMENT_MODE_CC = 'cc'
