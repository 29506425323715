import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import classnames from 'classnames'
import { useTranslation, Trans } from 'react-i18next'
import { Box, Typography, TextField, Button, useTheme } from '@material-ui/core'
import { useAuth } from 'auth'

import config from 'config'

const useStyles = makeStyles((theme) =>
  createStyles({
    socialButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '240px',
      height: '40px',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(6),
      cursor: 'pointer',
      borderRadius: '5px',
    },
    facebookButton: {},
    googleButton: {
      backgroundColor: '#4267b2',
      color: 'white',
      textAlign: 'center',
      '& img': {
        width: '30px',
      },
    },
  })
)

// import useAuthEvent from '../../../hooks/useAuth'

const SignIn = ({
  redirectUrl,
  setView,
  state,
  setError,
  error,
  setState,
  loginError,
  setFetching,
  fetching,
  setLoginError,
}) => {
  const styles = useStyles()
  // const router = useRouter()
  const theme = useTheme()
  const { updateAuth, AmplifyAuth, CognitoHostedUIIdentityProvider } = useAuth()
  const { t } = useTranslation('auth')

  const signInWithFacebookHandler = () => {
    if (fetching) {
      return
    }
    AmplifyAuth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
      customState: redirectUrl ? JSON.stringify({ redirectUrl }) : '',
    })
  }

  const signInWithGoogleHandler = () => {
    if (fetching) {
      return
    }
    AmplifyAuth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: redirectUrl ? JSON.stringify({ redirectUrl }) : '',
    })
  }

  const handleInputChange = (event) => {
    setLoginError()
    setState((s) => ({ ...s, [event.target.name]: event.target.value }))
  }

  const handleSignIn = async (event) => {
    try {
      setFetching(true)
      await AmplifyAuth.signIn({
        username: state.email,
        password: state.password,
      })

      await updateAuth()
      // router.replace(redirectUrl || '/')
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        setView('confirmSignUp')
        return
      }
      if (e.code === 'NotAuthorizedException') {
        setError(e.code)
        return
      }
      if (e.code === 'UserNotFoundException') {
        setError(e.code)
        return
      }

      console.error(e)
    } finally {
      setFetching(false)
    }
  }

  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={[3]}
      pt={6}
    >
      <TextField
        fullWidth
        label={t('attributes.email.label')}
        placeholder={t('attributes.email.placeholder')}
        name="email"
        value={state.email}
        onChange={handleInputChange}
        disabled={fetching}
      />
      <Box mb={5} />
      <TextField
        fullWidth
        label={t('attributes.password.label')}
        placeholder={t('attributes.password.placeholder')}
        type="password"
        name="password"
        value={state.password}
        onChange={handleInputChange}
        disabled={fetching}
      />
      <Box mb={5} />
      {loginError && (
        <Typography
          align="center"
          p={[3]}
          style={{ color: theme.palette.error.light }}
        >
          {t(`errors.${loginError}`, state)}
        </Typography>
      )}
      <Button
        width="240px"
        variant={'contained'}
        color="primary"
        disabled={fetching}
        onClick={handleSignIn}
      >
        {t('buttons.signIn')}
      </Button>
      {error && (
        <Typography
          align="center"
          p={[3]}
          style={{ color: theme.palette.error.light }}
        >
          {t(`errors.${error}`, state)}
        </Typography>
      )}

      <Box mb={2} />
      <Typography
        variant="body2"
        onClick={() => {
          setState((s) => ({ ...s, password: '' }))
          setView('passwordForgot')
        }}
        sx={{ cursor: 'pointer' }}
      >
        {t('buttons.signInPasswordForgot')}
      </Typography>
      <Box
        my={3}
        sx={{ borderBottom: '1px solid #ccc', height: '2px', width: '100%' }}
      />
      <Box
        className={classnames(styles.socialButton, styles.facebookButton)}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: '#4267b2',
          borderRadius: '5px',
          color: 'white',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        onClick={signInWithFacebookHandler}
      >
        <FacebookIcon style={{ fontSize: 34, color: '#fff' }} />
        <Typography>Login with Facebook</Typography>
      </Box>
      <Box mb={3} />
      <Box
        className={classnames(styles.socialButton, styles.googleButton)}
        onClick={signInWithGoogleHandler}
      >
        <Box
          width={30}
          height={30}
          mx={'1px'}
          sx={{
            backgroundColor: 'white',
            borderRadius: '5px',
          }}
        >
          <img src={`${config.assetsBaseUrl}images/login/iconaGoogleG.png`} />
        </Box>
        <Typography>Sign in with Google</Typography>
      </Box>

      <Box align="center" p={[3]}>
        <Trans
          t={t}
          i18nKey="messagePrivacyAndTerms"
          components={{
            privacy: (
              <a
                href="/privacy-policy"
                target="_blank"
                style={{ color: theme.palette.primary.light }}
              />
            ),
            terms: (
              <a
                href="/terms-and-conditions"
                target="_blank"
                style={{ color: theme.palette.primary.light }}
              />
            ),
          }}
        />
      </Box>
    </Box>
  )
}

SignIn.footerProps = {
  message: 'messageSignUp',
  goto: 'signUp',
  resetForm: {
    confirmCode: '',
    password: '',
    passwordConfirm: '',
  },
}

export default SignIn
