import React from 'react'
import { Container as ContainerMUI, Box } from '@material-ui/core'

import Footer from './Footer'

const Container = ({ children, setState, footerProps, setView }) => {
  return (
    <>
      <ContainerMUI>
        <Box m={4}>{children}</Box>
        {footerProps && (
          <Footer
            onClick={() => {
              setState((s) => ({
                ...s,
                ...footerProps.resetForm,
              }))
              setView(footerProps.goto)
            }}
            messageKey={footerProps.message}
          />
        )}
      </ContainerMUI>
    </>
  )
}

export default Container
