import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Button, useTheme } from '@material-ui/core'

const ResetPasswordSuccess = ({ setView, state }) => {
  const theme = useTheme()
  const { t } = useTranslation('auth')

  const handleResetPasswordSuccess = async (event) => {
    setView('signIn')
  }

  return (
    <Box
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={[3]}
      pt={3}
    >
      <Typography
        align="center"
        style={{ color: theme.palette.error.dark }}
        mb={5}
        fontSize={5}
      >
        {t('messagePasswordResetSuccess', state)}
      </Typography>
      <Box mb={5} />
      <Button
        color="primary"
        variant="contained"
        onClick={handleResetPasswordSuccess}
      >
        {t('buttons.signUpSuccess')}
      </Button>
    </Box>
  )
}

export default ResetPasswordSuccess
