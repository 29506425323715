import React from 'react'
import { Route } from 'react-router-dom'
import T from 'prop-types'

import Login from './components'
import { useAuth } from './useAuth'

const ProtectedRoute = ({
  children,
  authData,
  ProtectedTemplate = ({ children }) => <>{children}</>,
  LoginFlowTemplate = ({ children }) => <>{children}</>,
  ...props
}) => {
  const { currentSession } = useAuth()

  return (
    <Route {...props}>
      {currentSession ? (
        <ProtectedTemplate>{children}</ProtectedTemplate>
      ) : (
        <LoginFlowTemplate>
          <Login />
        </LoginFlowTemplate>
      )}
    </Route>
  )
}

export default ProtectedRoute

ProtectedRoute.propTypes = {
  children: T.node,
  authData: T.object,
}
