import React from 'react'
import { Button } from '@material-ui/core'

import { useAuth } from './useAuth'

const LogoutButton = () => {
  const { signOut } = useAuth()
  return <Button onClick={signOut}>Logout</Button>
}

export default LogoutButton
