import {
  PRODUCTION_STATUS_VERIFIED,
} from '../constants'

import getGinState from './getGinState'

function isCartReadyToDo(order) {
  let canGo = true

  order.relatedProducts?.forEach((product) => {
    if (getGinState(product) < PRODUCTION_STATUS_VERIFIED) {
      canGo = false
    }
  })
  return canGo
}

export default isCartReadyToDo
