import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Card, Typography} from '@material-ui/core'
import {useAuth} from 'auth'
import axios from 'axios'

import config from '../../config'
import {useBuilderConfig} from "../../hooks/useBuilderConfig"

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 40,
    marginBottom: 10,
  },
  statsContainer: {
    display: 'flex',
    justifyContent: "space-between"
  }
}))

const mapType = {
  bottle: 'Bottiglie',
  cork: 'Tappi',
  coloring: 'Colorazione',
  capsule: 'Capsule',
  packaging: 'Packaging',
}

const DayOrderStats = () => {
  const classes = useStyles()
  const [stats, setStats] = useState([])
  const { getAccessToken } = useAuth()
  const {getCustomizationLabel} = useBuilderConfig()

  useEffect(() => {
    const runStats = async () => {
      const accessToken = await getAccessToken()

      const result = await axios.get(
        `${config.serverUrl}orders/day-stats`,
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      )
      setStats(result.data.data.stats)
    }

    runStats()
  }, [])

  return (
    <Card className={classes.card}>
      <div className={classes.statsContainer}>
      {stats && Object.entries(stats).map(([type, items]) => {
        return <div key={type}>
          <Typography variant="h2" color="textSecondary" gutterBottom>{mapType[type] || type}</Typography>
          {Object.entries(items).map(([model, quantity]) => {
            return <div key={model}>
              <Typography variant="h3">{getCustomizationLabel(type, model)} {quantity}</Typography>
            </div>
          })}
        </div>
      })}
      </div>
    </Card>
  )
}

export default DayOrderStats
