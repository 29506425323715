function getGinId(order) {
  if (order.relatedProducts?.length === 0) {
    return `${order.ginshopOrderNumber}-1`
  } else {
    const prog =
      order.relatedProducts?.filter((product) => product.ginId < order.ginId)
        .length + 1
    return `${order.ginshopOrderNumber}-${prog}`
  }
}

export default getGinId
