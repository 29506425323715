import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import ProtectedRoute from 'auth/ProtectedRoute'

import HomePage from './components/pages/HomePage'
import LoginFlowTemplate from './components/templates/LoginFlowTemplate'
import MainTemplate from './components/templates/MainTemplate'
import Orders from './components/pages/Orders'
import Order from './components/pages/Order'
import OrderP1 from './components/pages/OrderP1'
import {
  PRODUCTION_STATUS_NO_LABEL,
  PRODUCTION_STATUS_TO_DO,
  PRODUCTION_STATUS_SHIPPED,
  PRODUCTION_STATUS_TO_VERIFY,
  PRODUCTION_STATUS_VERIFIED,
  PRODUCTION_STATUS_DONE,
  PRODUCTION_STATUS_COMPLETED,
  PRODUCTION_STATUS_EXTERNALIZED,
  PRODUCTION_STATUS_COMPLETED_EXTERNAL,
} from './constants'
import OrdersP1 from './components/pages/OrdersP1'

const ordersRoutes = [
  {
    id: 'to-verify-orders',
    title: 'Prodotti da verificare',
    filters: [PRODUCTION_STATUS_TO_VERIFY],
  },
  {
    id: 'verified-orders',
    title: 'Prodotti verificati',
    filters: [PRODUCTION_STATUS_VERIFIED],
  },
  {
    id: 'missing-label-orders',
    title: 'Prodotti senza etichetta',
    filters: [PRODUCTION_STATUS_NO_LABEL],
  },
  {
    id: 'to-do-orders',
    title: 'Prodotti da fare',
    filters: [PRODUCTION_STATUS_TO_DO],
  },
  {
    id: 'done-orders',
    title: 'Prodotti fatti',
    filters: [PRODUCTION_STATUS_DONE],
  },
  {
    id: 'sent-orders',
    title: 'Prodotti inviati',
    filters: [PRODUCTION_STATUS_SHIPPED],
  },
  {
    id: 'delivered-orders',
    title: 'Prodotti consegnati',
    filters: [PRODUCTION_STATUS_COMPLETED],
  },
]
export default function MainRouter() {
  return (
    <BrowserRouter>
      <ProtectedRoute
        exact
        path="/"
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
      >
        <HomePage />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={`/:area/orders/:orderId`}
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
      >
        <Order />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/:area/orders-p1/:orderId`}
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
      >
        <OrderP1 />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/ordersbyprogression/:progressionId"
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
      >
        <Order isByProgression />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path="/ordersbyprogression"
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
      >
        <Order isByProgression />
      </ProtectedRoute>

      {ordersRoutes.map((order) => (
        <ProtectedRoute
          key={`/b2c/${order.id}`}
          exact
          path={`/:area/${order.id}`}
          LoginFlowTemplate={LoginFlowTemplate}
          ProtectedTemplate={MainTemplate}
          menus={['consumer']}
        >
          <Orders
            payStatus="payed"
            type={`${order.id}`}
            title={order.title}
            productionStatusFilters={order.filters}
          />
        </ProtectedRoute>
      ))}

      {ordersRoutes.map((order) => (
        <ProtectedRoute
          key={`/${order.id}-paginate`}
          exact
          path={`/:area/${order.id}/:page/:rowsPerPage`}
          LoginFlowTemplate={LoginFlowTemplate}
          ProtectedTemplate={MainTemplate}
        >
          <Orders
            payStatus="payed"
            type={`${order.id}`}
            title={order.title}
            productionStatusFilters={order.filters}
          />
        </ProtectedRoute>
      ))}

      <ProtectedRoute
        exact
        path={`/:area/to-do-orders-p1`}
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
        menus={['consumer']}
      >
        <OrdersP1
          payStatus="payed"
          type="to-do-orders-p1"
          title="Da fare"
          productionStatusFilters={[PRODUCTION_STATUS_TO_DO]}
        />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={`/:area/to-do-orders-p1/:page/:rowsPerPage`}
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
        menus={['consumer']}
      >
        <OrdersP1
          payStatus="payed"
          type="to-do-orders-p1"
          title="Da fare"
          productionStatusFilters={[PRODUCTION_STATUS_TO_DO]}
        />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/:area/to-do-orders-p2`}
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
        menus={['consumer']}
      >
        <OrdersP1
          payStatus="payed"
          type="to-do-orders-p2"
          title="Da fare"
          showCapsule
          productionStatusFilters={[PRODUCTION_STATUS_TO_DO]}
        />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={`/:area/to-do-orders-p2/:page/:rowsPerPage`}
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
        menus={['consumer']}
      >
        <OrdersP1
          payStatus="payed"
          type="to-do-orders-p2"
          title="Da fare"
          showCapsule
          productionStatusFilters={[PRODUCTION_STATUS_TO_DO]}
        />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={`/:area/in-progress`}
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
      >
        <Orders
          payStatus="payed"
          type={`externalized`}
          title={'Esternalizzati in corso'}
          productionStatusFilters={[PRODUCTION_STATUS_EXTERNALIZED]}
          customerTypes={[]}
        />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={`/:area/in-progress/:page/:rowsPerPage`}
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
      >
        <Orders
          payStatus="payed"
          type={`externalized`}
          title={'Esternalizzati in corso'}
          productionStatusFilters={[PRODUCTION_STATUS_EXTERNALIZED]}
        />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={`/:area/completed`}
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
      >
        <Orders
          payStatus="payed"
          type={`completed-external`}
          title={'Completati esterni'}
          productionStatusFilters={[PRODUCTION_STATUS_COMPLETED_EXTERNAL]}
        />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={`/:area/completed/:page/:rowsPerPage`}
        LoginFlowTemplate={LoginFlowTemplate}
        ProtectedTemplate={MainTemplate}
      >
        <Orders
          payStatus="payed"
          type={`completed-external`}
          title={'Completati esterni'}
          productionStatusFilters={[PRODUCTION_STATUS_COMPLETED_EXTERNAL]}
        />
      </ProtectedRoute>
    </BrowserRouter>
  )
}
