import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { SwipeableDrawer, Drawer as DrawerMUI, Hidden } from '@material-ui/core'

import AppBar from '../organisms/TopBar'
import Container from '../atoms/Container'

import { useAuth } from '../../auth'

import Drawer from './Drawer'

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
    paddingTop: '70px',
    boxSizing: 'border-box',
    minHeight: '100vh',
  },
  mainContent: {
    padding: '10px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '200px',
    },
  },
  drawerPaperMobile: {
    boxSizing: 'border-box',
    width: '200px',
    padding: '15px 10px',
  },
  drawerPaper: {
    boxSizing: 'border-box',
    marginTop: '65px',
    width: '200px',
    padding: '15px 10px',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}))

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const MainTemplate = ({ children }) => {
  const classes = useStyle()
  const { currentSession } = useAuth()
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const canAccessContent = currentSession?.idToken?.payload[
    'cognito:groups'
  ].includes('production')

  return (
    <div className={classes.root}>
      <AppBar toggleDrawer={handleDrawerToggle}/>
      {canAccessContent && (
        <Hidden smUp implementation="css">
          <SwipeableDrawer
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaperMobile,
            }}
          >
            <Drawer />
          </SwipeableDrawer>
        </Hidden>
      )}
      {canAccessContent && (
        <Hidden mdDown implementation="css">
          <DrawerMUI
            variant="permanent"
            anchor="left"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Drawer />
          </DrawerMUI>
        </Hidden>
      )}
      <Container>
        {canAccessContent && (
          <div className={classes.mainContent}>{children}</div>
        )}
        {!canAccessContent && (
          <div className={classes.mainContent}>
            Non si possiede l'autorizzazione per accedere a questa pagina
          </div>
        )}
      </Container>
    </div>
  )
}

export default MainTemplate
