import React from 'react'

import {Typography, Button, Box} from '@material-ui/core'
import axios from 'axios'
import {makeStyles} from '@material-ui/core/styles'

import {useAuth} from 'auth'

import config from 'config'

import {
  PRODUCTION_STATUS_COMPLETED_EXTERNAL,
  PRODUCTION_STATUS_DONE,
  PRODUCTION_STATUS_EXTERNALIZED,
  PRODUCTION_STATUS_NO_LABEL,
  PRODUCTION_STATUS_TO_DO,
  PRODUCTION_STATUS_TO_VERIFY,
  PRODUCTION_STATUS_VERIFIED
} from '../../constants'
import getGinState from '../../utils/getGinState'
import isCartReadyToDo from "../../utils/isCartReadyToDo"

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    width: '250px',
  },
  button: {
    width: '160px',
    marginRight: '3px',
  },
}))

const allowedStates = [
  PRODUCTION_STATUS_DONE,
  PRODUCTION_STATUS_NO_LABEL,
  PRODUCTION_STATUS_TO_DO,
  PRODUCTION_STATUS_TO_VERIFY,
  PRODUCTION_STATUS_VERIFIED,
]
const OrderWidgetAction = ({order, setOrder, isB2B}) => {
  const classes = useStyles()
  const {currentSession} = useAuth()

  const ginState = getGinState(order)
  const canGoToDo = isCartReadyToDo(order)

  const changeStatus = async (status) => {
    if (!window.confirm('Sei sicuro di voler cambiare lo stato?')) {
      return
    }

    const result = await axios.put(
      `${config.serverUrl}orders/${order.ginId}/status/${status}`,
      {},
      {
        headers: {
          Authorization: `bearer ${currentSession.idToken.getJwtToken()}`,
        },
      }
    )

    setOrder(result.data.data.order)
  }

  const goToVerify = () => changeStatus(PRODUCTION_STATUS_TO_VERIFY)
  const goVerified = () => changeStatus(PRODUCTION_STATUS_VERIFIED)
  const goToDo = () => changeStatus(PRODUCTION_STATUS_TO_DO)
  const goToDone = () => changeStatus(PRODUCTION_STATUS_DONE)
  const goExternalize = () => changeStatus(PRODUCTION_STATUS_EXTERNALIZED)
  const goCompletedExternal = () => changeStatus(PRODUCTION_STATUS_COMPLETED_EXTERNAL)

  if (!allowedStates.includes(ginState)) return false

  return (
    <Box pt={'15px'}>
      <Typography>Sposta in:</Typography>
      {ginState === PRODUCTION_STATUS_TO_VERIFY && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={goVerified}
          >
            Verificato
          </Button>
          {isB2B && <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.button}
            onClick={goExternalize}
          >
            Esternalizza
          </Button>}
        </div>
      )}
      {ginState === PRODUCTION_STATUS_EXTERNALIZED && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.button}
            onClick={goToVerify}
          >
            Da verific.
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.button}
            onClick={goCompletedExternal}
          >
            Completato
          </Button>
        </div>
      )}
      {ginState === PRODUCTION_STATUS_NO_LABEL && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.button}
            onClick={goToVerify}
          >
            Da verific.
          </Button>
        </div>
      )}
      {ginState === PRODUCTION_STATUS_VERIFIED && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.button}
            onClick={goToVerify}
          >
            Da Verific.
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={goToDo}
            disabled={!canGoToDo}
          >
            Da Fare
          </Button>
        </div>
      )}
      {ginState === PRODUCTION_STATUS_TO_DO && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={goVerified}
          >
            Verificato
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={goToDone}
          >
            Da spedire
          </Button>
        </div>
      )}
      {ginState === PRODUCTION_STATUS_DONE && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.button}
            onClick={goToDo}
          >
            Da fare
          </Button>
        </div>
      )}
    </Box>
  )
}

export default OrderWidgetAction
